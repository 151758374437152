import { camelizeKeys } from '@/camelize'
import axios from 'axios'

interface KenticoHeaders {
  'Content-Type': string;
  Authorization?: string;
}

interface KenticoParams {
  baseURL: string;
  headers: KenticoHeaders;
}

const projectId = process.env.VUE_APP_KENTICO_PROJECT_ID
const baseURL = `${process.env.VUE_APP_KENTICO_GRAPHQL_URL}/${projectId}`
const Authorization =
  process.env.VUE_APP_KENTICO_PREVIEW_API_KEY
    ? `Bearer ${process.env.VUE_APP_KENTICO_PREVIEW_API_KEY}`
    : null

const params = {
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
} as KenticoParams

if (Authorization) {
  params.headers.Authorization = Authorization
}

const httpClient = axios.create(params)

httpClient.interceptors.response.use(response => camelizeKeys(response))

export default httpClient
