import { ValidationRule } from '@vuelidate/core'
import { email, maxLength, minLength, required } from '@vuelidate/validators'
import { sameAs } from 'vuelidate/lib/validators'

const toSelectOption = (name: string) => ({ name, value: name })

export const servicesList = [
  'Expressway Services',
  'Special Services (To events such as concerts)'
].map(toSelectOption)

export const titlesList = [
  { name: 'Select', value: '' },
  ...['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'].map(name => ({
    name,
    value: `${name}.`
  }))
]

export const enquiriesList = [
  { name: 'Please Select', value: '' },
  ...[
    'Queries',
    'Timetable',
    'Fares & Tickets',
    'Refunds',
    'Lost Property',
    'Complaint'
  ].map(toSelectOption)
]

export const countiesList = [
  { name: 'Select', value: '' },
  ...[
    'Antrim',
    'Armagh',
    'Carlow',
    'Cavan',
    'Clare',
    'Cork',
    'Derry',
    'Donegal',
    'Down',
    'Dublin',
    'Fermanagh',
    'Galway',
    'Kerry',
    'Kildare',
    'Kilkenny',
    'Laois',
    'Leitrim',
    'Limerick',
    'Longford',
    'Louth',
    'Mayo',
    'Meath',
    'Monaghan',
    'Offaly',
    'Roscommon',
    'Sligo',
    'Tipperary',
    'Tyrone',
    'Waterford',
    'Westmeath',
    'Wexford',
    'Wicklow'
  ].map(toSelectOption)
]

export const repliesList = [
  { name: 'Please select', value: '' },
  { name: 'I do not want a reply', value: 'I do not want a Reply' },
  { name: 'Reply by email', value: 'Reply by E-Mail' },
  { name: 'Reply by phone', value: 'Reply by Phone' },
  { name: 'Reply by letter', value: 'Reply by Letter' }
]

export const hoursList = [
  { name: 'Hour', value: '' },
  ...Array(24)
    .fill(0)
    .map((_, i) => toSelectOption(i.toString().padStart(2, '0')))
]

export const minsList = [
  { name: 'Min', value: '' },
  ...Array(4)
    .fill(0)
    .map((_, i) => toSelectOption((i * 15).toString().padStart(2, '0')))
]

export const commonValidationRules = {
  title: {},
  fullName: { required, minLength: minLength(2), maxLength: maxLength(40) },
  email: { required, email },
  dateOfTravel: {},
  dateOfTravelHour: {},
  dateOfTravelMin: {},
  typeOfService: { required },
  typeOfEnquiry: { required },
  from: {},
  to: {},
  routeNo: {},
  details: {},
  likeReply: { required },
  country: {},
  zipcode: {},
  privacyPolicy: { sameAs: sameAs(() => true) }
}

export const dynamicallyRules =
  (likeReply: string): { [x: string]: { [x: string]: ValidationRule } } => {
    switch (likeReply) {
      case 'Reply by Phone':
        return {
          ...commonValidationRules,
          contactNumber: { required },
          state: {},
          address: {},
          city: {}
        }
      case 'Reply by Letter':
        return {
          ...commonValidationRules,
          contactNumber: {},
          state: { required },
          address: { required },
          city: { required }
        }
      default:
        return {
          ...commonValidationRules,
          contactNumber: {},
          state: {},
          address: {},
          city: {}
        }
    }
  }
